import React from "react"
import { Link } from "gatsby"
import { Box } from "theme-ui"

const Button = ( props ) => {

  return (
    <Box
      as={Link}
      to={props.url}
      sx={{
        color: `primary`,
        border: `none`,
        borderRadius: `5px`,
        backgroundColor: `white`,
        cursor: `pointer`,
        display: `inline-block`,
        fontFamily: `body`,
        fontSize: [0, 0, 0, 0, 0, 1],
        fontWeight: `bold`,
        py: 3,
        px: 6,
        textDecoration: `none`,
        textTransform: `uppercase`,
        "&:hover, &:active, &:focus": {
          backgroundColor: `primary`,
          color: `white`,
          textDecoration: `none`
        }
      }}
    >
      {props.text}
    </Box>
  )

}

export default Button