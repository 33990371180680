import React from "react"
import { YouTube } from "mdx-embed"
import { Box, Container, Grid, Themed } from "theme-ui"
import Button from "./button"
import getSlug from "speakingurl"

const SpeakerSingle = (props) => {

  const nameToURL = getSlug(props.name)

  return (
    <Container>
      <Grid
        gap={[5, 5, 5, 7, 7, 8]}
        sx={{
          gridTemplateColumns: `repeat(auto-fit, minmax(17.08rem, 1fr))`,
          py: 6
        }}
      >
      <Box
        sx={{
          h1: {
            fontSize: [5, 6],
            marginBottom: [4, 4, 4, 6, 6, 6],
          },
          ul: {
            padding: 0,
            margin: 0,
            listStyle: `none`
          },
          li: {
            marginBottom: [4, 4, 4, 3, 3, 3],
          },
          ".title-presentation": {
            fontWeight: `bold`
          }
        }}
      >
        <Themed.h1>{props.name}</Themed.h1>
        <Themed.p>{props.profession}</Themed.p>
        <Themed.p className="title-presentation">{props.presentation}</Themed.p>
        <Box
          sx={{
            marginTop: [4, 4, 4, 6, 6, 8],
            a: {
              marginTop: 3,
            }
          }}
        >
          {(props.hasspeach === "true") &&
          <Button
            url={`/ponencia-${nameToURL}.pdf`}
            text="Descarga ponencia (PDF)"
          />
          }
          <br />
          {(props.haspresentacion === "true") &&
          <Button
            url={`/presentacion-${nameToURL}.pdf`}
            text="Descarga presentacion (PDF)"
          />
          }
          <br />
          {(props.hasarticle === "true") &&
          <Button
            url={`/articulo-${nameToURL}.pdf`}
            text="Descarga artículo (PDF)"
          />
          }
        </Box>
      </Box>
      <Box
        sx={{
          ".mdx-embed": {
            backgroundColor: `primary`,
            padding: 4,
          }
        }}
      >
        <YouTube
          youTubeId={props.youtubeid}
          skipTo={props.skipTo}
        />
      </Box>
      </Grid>
    </Container>
  )

}

export default SpeakerSingle