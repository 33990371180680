import { lighten, darken } from "@theme-ui/color"

const theme = {
  scale: `1.5`,
  space: {
    "0": `0`,
    "1": `0.29rem`,
    "2": `0.44rem`,
    "3": `0.66rem`,
    "4": `1rem`,
    "5": `1.5rem`,
    "6": `2.25rem`,
    "7": `3.37rem`,
    "8": `5.06rem`,
    "9": `7.59rem`,
    "10": `11.39rem`,
    "11": `17.08rem`
  },
  fonts: {
    body: "\"Raleway\", system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", sans-serif",
    heading: "\"Raleway\", system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", sans-serif",
    monospace: "Menlo, monospace"
  },
  fontSizes: [
    `0.8rem`,
    `1rem`,
    `1.25rem`,
    `1.563rem`,
    `1.953rem`,
    `2.441rem`,
    `3.052rem`,
    `3.815rem`,
    `4.768rem`,
    `5.96rem`,
    `7.451rem`,
  ],
  fontWeights: {
    light: 400,
    body: 500,
    heading: 800,
    bold: 800,
  },
  letterSpacings: [
    0,
    `0.5px`,
    `1px`,
  ],
  lineHeights: {
    body: 1.5,
    heading: 1,
    none: 1,
    snug: 1.33,
    relaxed: 1.625,
  },
  colors: {
    text: `#76217b`, //lila
    background: `#f7ebe1`, //ocre claro
    primary: `#76217b`,
    primarydarken: darken(`primary`, 0.2),
    primarylight: lighten(`primary`, 0.4),
    primarylightest: lighten(`primary`, 0.55),
    secondary: `#F6E0BF`, //ocre
    muted: `#CFCFCF`,
    white: `#ffffff`,
    black: `#000000`,
    gray: [`#f1f1f1`, `#dedede`, `#acacac`, `#858585`, `#b1b1b1`, `#373737`, `#060606`], //build with primary color
  },
  breakpoints: [
    `640px`,
    `769px`,
    `1025px`,
    `1281px`,
    `1640px`,
  ],
  sizes: {
    px: `1px`,
    "0": `0`,
    "1": `0.29rem`,
    "2": `0.44rem`,
    "3": `0.66rem`,
    "4": `1rem`,
    "5": `1.5rem`,
    "6": `2.25rem`,
    "7": `3.37rem`,
    "8": `5.06rem`,
    "9": `7.59rem`,
    "10": `11.39rem`,
    "11": `17.08rem`,
    full: `100%`,
    screenHeight: `100vh`,
    screenWidth: `100vw`,
    phoneMaxWidht: `calc(100vw - 3.37rem)`,
    tabletMaxWidth: `calc(100vw - 5.06rem)`,
    desktopMaxWidth: `calc(100vw - 11.39rem)`,
    desktopLargeMaxWidth: `calc(100vw - 17.08rem)`,
  },
  forms: {
    input: {
      borderRadius: 0,
      border: `1px solid black`,
    },
    textarea: {
      borderRadius: 0,
    },
    label: {
      fontFamily: `body`,
    }
  },
  buttons: {
    primary: {
      color: `primary`,
      border: `none`,
      borderRadius: `5px`,
      backgroundColor: `white`,
      cursor: `pointer`,
      fontWeight: `bold`,
      py: 3,
      px: 6,
      textTransform: `uppercase`
    },
    secondary: {
      color: `white`,
      border: `none`,
      borderRadius: `5px`,
      backgroundColor: `black`,
      cursor: `pointer`,
      fontSize: 0,
      p: 3,
      textTransform: `uppercase`
    },
  },
  layout: {
  // Viene de breakpoint theme-ui
  // Container tiene 6 valores
  // Estan los 5 valores de breakpoints + uno por defecto (el primero)
  // Por lo tanto puedo declarar:
  // maxWidth: [`100%`, `50%`, `45%`, `30%`, `23%`, `15%`],
  // Donde:
  // minWidth 100%
  // minWidth 640px 50% 
  // minWidth 769px 45%
  // minWidth 1025px 30%
  // minWidth 1281px 23%
  // minWidth 1640px 15%
    container: {
      maxWidth: [`phoneMaxWidht`, `phoneMaxWidht`, `tabletMaxWidth`, `desktopMaxWidth`, `desktopMaxWidth`, `desktopLargeMaxWidth`],
    },
  },
  text: {
    heading: {
      color: `text`,
      fontFamily: `heading`,
      fontWeight: `heading`,
      lineHeight: `heading`,
    },
    base: {
      color: `text`,
      fontFamily: `body`,
      fontSize: [1, 1, 1, 1, 1, 2],
      fontWeight: `body`,
      lineHeight: `snug`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
    },
  },
  styles: {
    root: {
      boxSizing: `border-box`,
      padding: 0,
      position: `relative`,
      ".tl-edges": {
        overflowY: `hidden`,
      },
    },
    a: {
      color: `primary`,
      cursor: `pointer`,
      fontFamily: `body`,
      fontWeight: `bold`,
      transition: `all 0.3s ease-in-out`,
      ":hover": {
        color: `primarydarken`,
      },
    },
    audio: {
      width: `100%`,
    },
    blockquote: {
      borderLeftColor: `white`,
      borderLeftStyle: `solid`,
      borderLeftWidth: `1px`,
      fontFamily: `body`,
      fontSize: [2, 2, 2, 3, 3, 3],
      marginLeft: 0,
      paddingLeft: 3,
      ":before": {
        content: `"“"`,
      },
      ":after": {
        content: `"”"`,
      },
    },
    caption: {
      textAlign: `left`
    },
    code: {
      fontFamily: `monospace`,
      fontSize: `inherit`
    },
    h1: {
      variant: `text.heading`,
      fontSize: [3, 3, 3, 5, 5, 5],
      mt: 1,
      mb: 1,
    },
    h2: {
      variant: `text.heading`,
      fontSize: [2, 2, 2, 3, 3, 4],
      marginTop: [5, 5, 6, 6, 6, 7],
      marginBottom: [2, 2, 2, 3, 3, 3],
    },
    h3: {
      variant: `text.heading`,
      fontSize: [1, 1, 1, 2, 2, 3],
      marginTop: [5, 5, 6, 6, 6, 7],
      marginBottom: [2, 2, 2, 3, 3, 3],
    },
    h4: {
      variant: `text.heading`,
      fontSize: [1, 1, 1, 1, 1, 2],
    },
    h5: {
      variant: `text.heading`,
      fontSize: 1,
    },
    h6: {
      variant: `text.heading`,
      fontSize: 1,
    },
    hr: {
      bg: `muted`,
      border: 0,
      height: `1px`,
      my: 3,
      mx: 0,
    },
    img: {
      maxWidth: `100%`,
    },
    p: {
      variant: `text.base`,
      marginBottom: [3, 3, 3, 3, 4, 4],
    },
    pre: {
      fontFamily: `monospace`,
      overflowX: `auto`,
      code: {
        color: `inherit`
      }
    },
    table: {
      borderCollapse: `separate`,
      borderSpacing: 0,
      borderTop: theme => `1px solid ${theme.colors.gray[4]}`,
      borderLeft: theme => `1px solid ${theme.colors.gray[4]}`,
      color: `text`,
      fontFamily: `body`,
      fontSize: 1,
      fontWeight: `body`,
      lineHeight: `snug`,
      marginBottom: 4,
      width: `100%`,
    },
    thead: {
      backgroundColor: theme => `${theme.colors.gray[1]}`,
    },
    th: {
      textAlign: `left`,
      borderBottom: theme => `1px solid ${theme.colors.gray[4]}`,
      borderRight: theme => `1px solid ${theme.colors.gray[4]}`,
      padding: 2,
    },
    td: {
      textAlign: `left`,
      borderBottom: theme => `1px solid ${theme.colors.gray[4]}`,
      borderRight: theme => `1px solid ${theme.colors.gray[4]}`,
      padding: 2,
    },
    ul: {
      fontFamily: `body`,
      listStyle: `circle`,
      marginLeft: 2,
      paddingLeft: 2,
      marginBottom: [3, 3, 3, 3, 3, 4],
    },
    li: {
      fontFamily: `body`,
      fontSize: [1, 1, 1, 1, 1, 2],
      my: [0, 0, 0, 2, 3, 3],
    },
    svg: {
      verticalAlign: `middle`,
    },
  }

}

export default theme